// QuizView.jsx

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import '../css/QuizView.css'
import styled from 'styled-components';
import { Carousel, Card, Progress, Skeleton, Row, Button, Col, Form, Empty, Modal, Tooltip, Space, Steps } from 'antd';
import { LogoutOutlined, UpCircleTwoTone, DownCircleTwoTone, InfoCircleOutlined } from '@ant-design/icons';
import { ShortcutProvider, ShortcutConsumer } from 'react-keybind';
import GenericSurveyView from './generic-survey-view';
import SectionComponent, { getTypeFromQuestion, TIME_LIMIT } from './SectionComponent';
import { MetaTags, getIntFromString, InputValuesObject, getUniqueListBy } from '../../look';


import ROUTE from '../route';
import QuizLoading from './QuizLoading';
import SaveInCompleteQuizResponse from './SaveInCompleteQuizResponse';
import SurveyQuizShortcuts, { getShortCutObj } from '../components/SurveyQuizShortcuts';

const { confirm } = Modal;
const Step = Steps.Step;
const QuizView = props => {
  const { loading, orgSurveyById, onSubmit, publicSurveyById, setPrevTotalDuration, quizStartTime, history, submitted } =
    props;
  const ref = React.useRef();
  const mobileRef = React.useRef();
  const currentSectionRef = React.useRef();
  const [ currentPage, setCurrentPage ] = React.useState( 1 )
  const showOkRef = React.useRef();
  const [prevPressed, setPrevPressed] = useState(0)
  const [showOk, setShowOk] = React.useState([false]);
  const [timeoutId, setTimeoutId] = React.useState(null);
  const [currentSection, setCurrentSection] = React.useState(1);
  const [formValues, setFormValues] = React.useState([]);
  const [quesAnswered, setQuesAnswered] = useState(0)
  const [formState, setFormState] = useState([])
  const [startTime, setStartTime] = React.useState(new Date().getTime());
  const [isForw, setIsForw] = React.useState(true);
  const [isQues19, setIsQues19] = useState()
  const [currentMatrixQuestion, setCurrentMatrixQuestion] = useState(0);
  const [enableShortCuts, setEnableShortCuts] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [clickedQuestions,setClickedQuestions] = useState([])
  const [ questionValues, setQuestionValues ] = React.useState( [] )
  const [selectedq_id,setselectedId]=React.useState()

  useEffect(() => {
    if (!enableShortCuts) {
      setEnableShortCuts(true);
    }
  }, [enableShortCuts]);
console.log('ccyyc77tt8b',isForw,currentSection,quesAnswered,prevPressed)
  // useEffect(() => {
  //   function saveAnswerOnUnmount() {
  //     console.log(form.getFieldsValue(true));
  //   }

  //   return () => saveAnswerOnUnmount();
  // }, []);
  useEffect(() => {
    if (currentSection === 19) {
      setIsQues19(true)
    } else {
      setIsQues19(false)

    }

  }, [currentSection])
  const customDot = (icon, { status }) =>           {
    switch (status) {
      case 'process':
        return <span className="custom-dot custom-dot-process"><img src={"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1707734951/WorkplaceResilienceProduction/Survey/Images/stepper-complete-icon_lhw6cs.svg"} alt=""  style={{width:"25px",marginLeft:"-5px"}}/></span>;
      case 'wait':
        return <span className="custom-dot custom-dot-finish"><img src={"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1707734895/WorkplaceResilienceProduction/Survey/Images/stepper-pending_a7n4ho.svg"} alt="" style={{width:"18,px",marginLeft:"-2px"}}/></span>;
      default:
        return <span className={`custom-dot custom-dot-${status}`}><img src={"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1707734895/WorkplaceResilienceProduction/Survey/Images/stepper-pending_a7n4ho.svg"} alt="" style={{width:"18px",marginLeft:"-2px"}}/></span>;
    }
  };
  const surveyName = publicSurveyById?.name
  const question = [].concat.apply(
    [],
    (orgSurveyById ? orgSurveyById : publicSurveyById)?.groupSet?.edges
      .sort(function (a, b) {
        return a.node.sequence - b.node.sequence;
      })
      .map(({ node: grpSet }, idx) =>
        grpSet.matrix
          ? { ...grpSet }
          : grpSet?.questionSet?.edges?.map(({ node: questionSet }) => ({
            ...grpSet,
            questionSet: [questionSet]
          }))
      )
  );
  const currentIsMatrix = question[currentSection - 1]?.matrix;
  let totalLength = question.length;
  console.log('orgSurveyByI22publicSurveyById = ::',questionValues, currentSection,currentPage, formValues,totalLength,question)

  const shortcutObj = question?.map(q =>
    Array.isArray(q?.questionSet)
      ? q?.questionSet?.map(getShortCutObj)
      : q?.questionSet?.edges?.map(({ node }) => getShortCutObj(node))
  );
  // console.log(shortcutObj);

  function showDeleteConfirm() {
    confirm({
      title: 'Are you sure you want to exit this survey?',
      icon: <LogoutOutlined />,
      content:
        'We will save all your current responses. You can choose to continue from here the next time you start this survey.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        history.push(ROUTE.allOrganizationSurvey);
      },
      onCancel() {
        // console.log('Cancel');
      }
    });
  }

  const renderFormSections = survey => {
    // const carouseSettingChange = isForw
    //   ? {
    //       beforeChange
    //     }
    //   : {
    //       afterChange
    //     };
    // const carouselSettings = {
    //   currentIsMatrix,
    //   accessibility: false,
    //   // ...carouseSettingChange,
    //   beforeChange,
    //   // afterChange,
    //   // ref,
    //   ref: mobileRef,
    //   dotPosition: 'left',
    //   dots: false,
    //   autoplay: false,
    //   infinite: false
    // };

    // const carouselMobileSettings = {
    //   currentIsMatrix,
    //   accessibility: false,
    //   // ...carouseSettingChange,
    //   beforeChange,
    //   // afterChange,
    //   // ref: mobileRef,
    //   ref,
    //   dotPosition: 'left',
    //   dots: false,
    //   autoplay: false,
    //   infinite: false
    // };

    function timeoutFunc(srNo) {
      const id = setTimeout(() => nextStep(form.getFieldsValue(true), srNo), TIME_LIMIT * 1000);
      // console.log('Sup!', id);
      setTimeoutId(id);
    }

    function closetimer() {
      // console.log('Sup!!', timeoutId);
      clearTimeout(timeoutId);
    }

    function getValue(value, name) {
      if (!currentIsMatrix) {
        const questionFiltered = question.filter(q => {
          return Array.isArray(q?.questionSet) && q?.questionSet?.filter(qs => qs.id === name).length > 0;
        })[0]?.questionSet[0];
        switch (questionFiltered?.choiceSet?.edges[0]?.node?.inputType) {
          case InputValuesObject.date:
            return value?.format('YYYY/MM/DD');
          case InputValuesObject.datetime:
            return value?.format('YYYY/MM/DD HH:mm:ss');
          case InputValuesObject.month:
            return value?.format('M');
          case InputValuesObject.time:
            return value?.format('HH:mm:ss');
          case InputValuesObject.week:
            return value?.week();
          case InputValuesObject.range:
            return value === undefined || value === 0 ? 0 : value;
          default:
            return value || '';
        }
      } else {
        return value || '';
      }
    }

    function getResponseDuration(endTime, value, k) {
      let time = 0;
      const existingValue = formValues.filter(f => f.questionId === getIntFromString(k))[0];

      if (!currentIsMatrix) {
        time =
          question[currentSection - 1]?.questionSet[0].id === k
            ? existingValue
              ? existingValue?.responseDuration + (endTime - startTime) / 1000 // returns time in seconds
              : (endTime - startTime) / 1000 // returns time in seconds
            : existingValue?.responseDuration || 10;
      } else {
        time = existingValue
          ? existingValue?.answer === '' || existingValue?.answer === undefined
            ? value !== '' || value !== undefined
              ? 10
              : (endTime - startTime) / 1000
            : existingValue?.responseDuration
          : value === '' || value === undefined
            ? 10
            : (endTime - startTime) / 1000;
      }
      return time;
    }

    const prevStep = () => {
      if (currentSection === 0) {
        return false;
      }
      closetimer();
      setIsForw(false);
      setCurrentSection(currentSection - 1)
      setPrevPressed(prev => prev + 1)
      // ref.current.prev();
      // mobileRef.current.prev();
      // if (document) {
      //   if (document.body) {
      //     document.body.scrollTop = 0;
      //     document.documentElement.scrollTop = 0;
      //   }
      // }
    };

    // function afterChange(current) {
    //   setCurrentSection(current + 1);
    // }
    function beforeChange(current) {
      console.log('current = ::', current)
      if (!isForw && current === 0) {
        return false;
      }
      setCurrentSection(currentSection - 1);
      setCurrentSectionFromRef(isForw ? current + 2 : current);
      setCurrentMatrixQuestion(0);
    }

    const nextStep = values => {
      setCurrentSection(currentSection + 1); 
      setPrevPressed(prevPressed + 1)
      console.log('quessdsjbcurrentsec4',quesAnswered,currentSection)
      setIsForw(true);
      if (currentSection !== totalLength && currentSection < quesAnswered ) {setCurrentSection(currentSection + 1); setPrevPressed(prevPressed + 1)};
      // if (currentSection !== totalLength && currentSection < quesAnswered ) {setCurrentSection(currentSection + 1) && setPrevPressed(prevPressed + 1)};
      closetimer();
      setShowOk(question.map(() => false));
      const endTime = new Date().getTime();
      let input = [];
      Object.keys(values).map((k, i) => {
        input.push({
          questionId: getIntFromString(k),
          answer: String(getValue(values[k], k)),
          responseDuration: getResponseDuration(endTime, values[k], k)
        });
      });
      setFormValues(getUniqueListBy([...formValues, ...input], 'questionId'));
      // if (document) {
      //   if (document.body) {
      //     document.body.scrollTop = 0;
      //     document.documentElement.scrollTop = 0;
      //   }
      // }
      setStartTime(endTime);
      return getUniqueListBy([...formValues, ...input], 'questionId');
    };

    function getFieldName(index, current) {
      return shortcutObj[current - 1][index].name;
    }

    function getFieldType(index, current) {
      return shortcutObj[current - 1][index].type;
    }

    function getFieldValue(name, optionKey, current) {
      const fieldValue = shortcutObj[current - 1].filter(q => q.name === name)[0][optionKey];
      const currentFieldType = getFieldType(0, current);
      return currentFieldType === InputValuesObject.radio ? fieldValue : [...(form.getFieldValue(name) || []), fieldValue];
    }
    function setCurrentSectionFromRef(value) {
      currentSectionRef.current.innerHTML = Number(value);
    }
    function setShowOkFromRef(value) {
      showOkRef.current.innerHTML = JSON.stringify(value);
    }

    const setValueFromShortcut = optionKey => {
      // console.log(optionKey);
      const showOkFromRef = JSON.parse(showOkRef?.current?.innerHTML);
      const currentSectionFromRef = Number(currentSectionRef?.current?.innerHTML);
      const currentFieldType = getFieldType(0, currentSectionFromRef);
      // console.log(currentSection, currentSectionFromRef);
      const fieldNames = Object.keys(form?.getFieldsValue(true));
      const matrix = question[currentSectionFromRef - 1]?.matrix;
      // console.log(fieldNames, currentMatrixQuestion, matrix);

      if (matrix && fieldNames.length > 0) {
        const currentQuestionIndex = shortcutObj[currentSectionFromRef - 1]
          .map(s => fieldNames.indexOf(s.name) === -1)
          .indexOf(true);

        if (currentQuestionIndex !== -1) {
          const fieldName = getFieldName(currentQuestionIndex, currentSectionFromRef);
          const fieldValue = shortcutObj[currentSectionFromRef - 1].filter(q => q.name === fieldName)[0][optionKey];
          if (fieldValue) {
            form.setFieldsValue(
              _.set(
                form.getFieldsValue(true),
                getFieldName(currentQuestionIndex, currentSectionFromRef),
                getFieldValue(fieldName, optionKey, currentSectionFromRef)
              )
            );
            if (currentQuestionIndex === shortcutObj[currentSectionFromRef - 1].length - 1) {
              if (currentSectionFromRef === totalLength) onSubmit(nextStep(form.getFieldsValue(true)));
              else nextStep(form.getFieldsValue(true));
              setCurrentMatrixQuestion(0);
            } else setCurrentMatrixQuestion(currentQuestionIndex + 1);
          }
        }
      } else {
        if (currentFieldType === InputValuesObject.radio || currentFieldType === InputValuesObject.checkbox || matrix) {
          const firstFieldName = getFieldName(0, currentSectionFromRef);
          const fieldValue = shortcutObj[currentSectionFromRef - 1].filter(q => q.name === firstFieldName)[0][optionKey];
          if (fieldValue) {
            // console.log('empty', firstFieldName);
            form.setFieldsValue(
              _.set(
                form.getFieldsValue(true),
                firstFieldName,
                getFieldValue(firstFieldName, optionKey, currentSectionFromRef)
              )
            );

            if (!matrix) {
              // console.log(currentFieldType);
              if (currentFieldType === InputValuesObject.radio) {
                // nextStep(form.getFieldsValue(true));
                setCurrentMatrixQuestion(1);
                setShowOk(question.map((q, qi) => (true ? qi === currentSectionFromRef - 1 : false)));
                setShowOkFromRef(question.map((q, qi) => (true ? qi === currentSectionFromRef - 1 : false)));

                // console.log(
                //   showOkFromRef,
                //   question.map((q, qi) => (true ? qi === currentSectionFromRef - 1 : false)),
                //   currentSectionFromRef
                // );

                if (!showOkFromRef[currentSectionFromRef - 1]) {
                  timeoutFunc(currentSectionFromRef);
                  // setShowOk(question.map((q, qi) => (false ? qi === currentSectionFromRef - 1 : false)));
                  // setShowOk(question.map((q, qi) => (true ? qi === currentSectionFromRef - 1 : false)));
                  // setShowOkFromRef(question.map((q, qi) => (false ? qi === currentSectionFromRef - 1 : false)));
                  // setShowOkFromRef(question.map((q, qi) => (true ? qi === currentSectionFromRef - 1 : false)));
                  // closetimer();
                }
              } else {
                setCurrentMatrixQuestion(form?.getFieldsValue(true)[firstFieldName].length);
              }
            } else setCurrentMatrixQuestion(1);
          }
        }
      }
    };
    let handleClickSelect = (e,qSerial) => {
      console.log('questionserialno5hv === ::',qSerial)
    }
    console.log('quesAnsweredquesAnsweredquesAnswered == ::', quesAnswered);
    return (
      <>
        <ShortcutProvider>
          {enableShortCuts && shortcutObj.length > 0 && (
            <SurveyQuizShortcuts
              onNext={() => form.submit()}
              onPrev={prevStep}
              setValueFromShortcut={setValueFromShortcut}
              currentMatrixQuestion={currentMatrixQuestion}
              visible={visible}
              setVisible={setVisible}
            />
          )}

          {/* <Button onClick={() => setValueFromShortcut(Math.random() > 0.5 ? 'a' : 'b')}>Testing</Button> */}
          <span ref={currentSectionRef} style={{ display: 'none' }}>
            1
          </span>

          <span ref={showOkRef} style={{ display: 'none' }}>
            {JSON.stringify([false])}
          </span>

          {!submitted && survey?.id && (
            <SaveInCompleteQuizResponse
              form={form}
              formValues={formValues}
              itemName={survey?.id}
              getValue={getValue}
              setFormValues={setFormValues}
              setPrevTotalDuration={setPrevTotalDuration}
              startTime={quizStartTime}
            />
          )}

          <ShortcutConsumer>
            {({ keys: allKeys }) =>
              survey?.groupSet.edges.length > 0 ? (
                <>
                  <MetaTags title={survey[currentSection]?.label || survey?.name} description="This is survey page." />
                  <Progress
                    percent={Math.round((currentSection / totalLength) * 100)}
                    strokeColor={{
                      '0%': '#108ee9',
                      '100%': '#87d068'
                    }}
                    style={{
                      display: 'block'
                    }}
                    showInfo={false}
                    status="active"
                  />

                  <Form
                    style={{ minHeight: '100%' }}
                    form={form}
                    name={survey?.name}
                    // layout={'vertical'}
                    onFinish={values => (currentSection -1 === totalLength ? onSubmit(nextStep(values)) : nextStep(values))}
                  // requiredMark={false}
                  // scrollToFirstError={true}
                  // initialValues={initialValues}
                  >
                    <Row style={{padding:'28px'}}>
                      
                      <Col lg={24} md={24} xs={24}>
                        <h3 style={{ fontWeight: 'bold', margin: '5vh' }}>
                          {survey[currentSection]?.label || survey?.name}
                        </h3>
                        {/* <div style={{ minHeight: '75vh', margin: 'auto', maxWidth: '100%', width: '100%' }}> */}
                          <QuesDiv >
                            {/* <div
                              {...(!window?.frameElement ? { style: { margin: !currentIsMatrix ? '8vh 2vh' : '0 5vh' } } : {})}
                            > */}
                              {/* <Row gutter={[24, 24]}>
                                <CustomCarousel  {...carouselSettings} >
                                  {question?.map((grpSet, idx) => (
                                      <SectionComponent
                                      isQues19={isQues19}
                                      surveyName={surveyName}
                                        key={idx}
                                        quesIdx={idx}
                                        srNo={idx + 1}
                                        form={form}
                                        currentIsMatrix={currentIsMatrix}
                                        currentMatrixQuestion={currentMatrixQuestion}
                                        currentSection={currentSection}
                                        matrix={grpSet?.matrix}
                                        timeoutFunc={timeoutFunc}
                                        closetimer={closetimer}
                                        nextStep={e => nextStep(form.getFieldsValue(true), e)}
                                        sectionLabel={grpSet?.label}
                                        sectionSequence={grpSet?.sequence}
                                        questionList={
                                          Array.isArray(grpSet?.questionSet)
                                            ? grpSet?.questionSet?.map(node => node)
                                            : grpSet?.questionSet?.edges?.map(({ node }) => node)
                                        }
                                        showOk={showOk[currentSection - 1]}
                                        setShowOk={e =>
                                          setShowOk(question.map((q, qi) => (e ? qi === currentSection - 1 : false)))
                                        }
                                      />
                                  ))}
                                </CustomCarousel>
                              </Row> */}
                              <div className='generic-survey-skil-stepper'>
                                <Steps direction="vertical" current={currentPage > 1 ? (currentSection - 1) - ((currentPage - 1) * 10) : currentSection - 1} progressDot={customDot}>
                                  {question?.map((grpSet, idx) => 
                                    {
                                      // let cleanedGrpSet = question
                                    let newGrpSet = {grpSet,id:idx, questionText:grpSet?.questionSet[0]?.questionText,choiceSet:grpSet?.questionSet[0]?.choiceSet}
                                    let matrixGrpSet = {grpSet, label:grpSet?.label, quesLabel : grpSet?.questionSet[0]?.questionText}
                                    // console.log('matrix label derived from this object ddd33r3r',( matrixGrpSet?.quesLabel || matrixGrpSet?.label)?.length, grpSet)
                                    // console.log('matrix label derived from this object ', grpSet)

                                    return(<Step key={currentSection - 1} id={((currentPage - 1) * 10) + idx} description={
                                      <GenericSurveyView
                                        setClickedQuestions={setClickedQuestions}
                                        clickedQuestions={clickedQuestions}
                                        quesAnswered={quesAnswered}
                                        setQuesAnswered={setQuesAnswered}
                                        prevPressed={prevPressed}
                                        setCurrentSection={setCurrentSection}
                                        key={((currentPage - 1) * 10) + idx}
                                        idx={idx}
                                        srNo={(((currentPage - 1) * 10) + idx) + 1}
                                        currentPage={currentPage}
                                        form={form}
                                        matrix={grpSet?.matrix}
                                        currentIsMatrix={currentIsMatrix}
                                        currentMatrixQuestion={currentMatrixQuestion}
                                        ref={ref}
                                        formValues={formValues}
                                        currentSection={currentSection}
                                        sectionLabel="value"
                                        sectionSequence={currentSection}
                                        onClick={(e)=>handleClickSelect(e,(((currentPage - 1) * 10) + idx) + 1)}
                                        timeoutFunc={timeoutFunc}
                                        closetimer={closetimer}
                                        selectedId={e => setselectedId(e)}
                                        nextStep={(e) => nextStep(form.getFieldsValue(true), e)}
                                        matrixLabel={Array.isArray(grpSet?.questionSet) ? null : grpSet?.label ? grpSet?.label : grpSet?.name}
                                        matrixGrpSet={matrixGrpSet}
                                        // questionList={[newGrpSet]}
                                        // questionList={
                                        //   grpSet?.choiceSet
                                        //     ? [newGrpSet] 
                                        //     : [matrixGrpSet]
                                        // }
                                        questionList={
                                          Array.isArray(grpSet?.questionSet)
                                            ? grpSet?.questionSet?.map(node => node)
                                            : grpSet?.questionSet?.edges?.map(({ node }) => node)
                                             
                                        }
                                        showOk={showOk[currentSection - 1]}
                                        setShowOk={(e) =>
                                          setShowOk(
                                            questionValues.map((q, qi) =>
                                              e ? qi === currentSection - 1 : false
                                            )
                                          )
                                        }
                                      />}
                                    />)
                                  
                                  }
                                  )}
                                  <Step />
                                </Steps>
                              </div>
                            {/* </div> */}
                          </QuesDiv>
                        {/* </div> */}

                        <Row style={{ position: "fixed", bottom: "5rem", left: "5rem" }}>
                          <Col span={1} />
                          <Col lg={20} md={18}>
                            <Button type="danger" onClick={showDeleteConfirm} size="small" shape="round">
                              <LogoutOutlined />
                              Quit survey
                            </Button>
                          </Col>
                          <Col span={3} align="center" style={{ position: "fixed", bottom: "5rem", right: "8rem" }}>
                            <Space>
                              <Tooltip title={'Shortcuts'}>
                                <Button
                                  icon={<InfoCircleOutlined twoToneColor="#87d068" style={{ fontSize: '35px' }} />}
                                  type="link"
                                  size="large"
                                  onClick={() => setVisible(true)}
                                />
                              </Tooltip>

                              <Tooltip title={'Previous'}>
                                <Button
                                  icon={<UpCircleTwoTone twoToneColor="#87d068" style={{ fontSize: '35px' }} />}
                                  disabled={currentSection === 1}
                                  type="link"
                                  onClick={prevStep}
                                  size="large"
                                />
                              </Tooltip>
                              {currentSection - 1 === totalLength ? (
                                <Button type="primary" htmlType="submit" size="large" shape="round">
                                  Submit
                                </Button>
                              ) : (
                                <Tooltip title={'Next'}>
                                  <Button
                                    type="link"
                                    htmlType="submit"
                                    size="large"
                                    shape={'round'}
                                    icon={<DownCircleTwoTone twoToneColor="#87d068" style={{ fontSize: '35px' }} />}
                                  />
                                </Tooltip>
                              )}
                            </Space>
                          </Col>
                          <Col span={1} />
                        </Row>
                      </Col>

                      {/* <Col lg={0} md={24} xs={24}>
                        <Row justify="space-around" gutter={10}>
                          <Col>
                            <h3 style={{ fontWeight: 'bold', margin: '1vh' }}>
                              {survey[currentSection]?.label || survey?.name}
                            </h3>
                          </Col>

                          <Col>
                            <Button
                              type="danger"
                              onClick={showDeleteConfirm}
                              size="small"
                              shape="circle"
                              icon={<LogoutOutlined />}
                            />
                          </Col>
                        </Row>

                        <Row gutter={[24, 24]} style={{ minHeight: '80vh' }}>
                          <CustomNativeCarousel {...carouselMobileSettings}>
                            {question?.map((grpSet, idx) => (
                              <Col>
                                <SectionComponent
                                  key={idx}
                                  srNo={idx + 1}
                                  form={form}
                                  currentIsMatrix={currentIsMatrix}
                                  currentMatrixQuestion={currentMatrixQuestion}
                                  currentSection={currentSection}
                                  matrix={grpSet?.matrix}
                                  timeoutFunc={timeoutFunc}
                                  closetimer={closetimer}
                                  nextStep={e => nextStep(form.getFieldsValue(true), e)}
                                  sectionLabel={grpSet?.label}
                                  sectionSequence={grpSet?.sequence}
                                  questionList={
                                    Array.isArray(grpSet?.questionSet)
                                      ? grpSet?.questionSet?.map(node => node)
                                      : grpSet?.questionSet?.edges?.map(({ node }) => node)
                                  }
                                  showOk={showOk[currentSection - 1]}
                                  setShowOk={e =>
                                    setShowOk(question.map((q, qi) => (e ? qi === currentSection - 1 : false)))
                                  }
                                />
                              </Col>
                            ))}
                          </CustomNativeCarousel>
                        </Row>

                        <Row gutter={[24, 24]} justify="end" style={{ minHeight: '5vh' }}>
                          <Col>
                            <Space align="center">
                              <Tooltip title={'Previous'}>
                                <Button
                                  icon={<UpCircleTwoTone twoToneColor="#87d068" style={{ fontSize: '35px' }} />}
                                  disabled={currentSection === 1}
                                  type="link"
                                  onClick={prevStep}
                                  size="large"
                                />
                              </Tooltip>
                              {currentSection === totalLength ? (
                                <Button type="primary" htmlType="submit" size="large" shape="round">
                                  Submit
                                </Button>
                              ) : (
                                <Tooltip title={'Next'}>
                                  <Button
                                    type="link"
                                    htmlType="submit"
                                    size="large"
                                    shape={'round'}
                                    icon={<DownCircleTwoTone twoToneColor="#87d068" style={{ fontSize: '35px' }} />}
                                  />
                                </Tooltip>
                              )}
                            </Space>
                          </Col>
                        </Row>
                      </Col> */}
                    </Row>
                  </Form>
                </>
              ) : survey ? (
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <Empty />
                </div>
              ) : null
            }
          </ShortcutConsumer>
        </ShortcutProvider>
      </>
    );
  };

  return (
    <MobilePadding>
      <div>
        {loading && <QuizLoading />}
        {!loading && orgSurveyById ? renderFormSections(orgSurveyById) : renderFormSections(publicSurveyById)}
      </div>
    </MobilePadding>
  );
};

export default QuizView;

const MobilePadding = styled.div`
  /* background: #f5f5f5; */
  margin: -5vh;
  /* padding: 5vh 0; */
  min-height: 100vh;

  @media only screen and (max-width: 480px) {
    padding: 0 12px;
  }
`;

const CustomCarousel = styled(Carousel)`
  /* Carousel (slick) */
  .slick-list {
    height: ${props => (props.currentIsMatrix ? '75vh !important' : '90vh !important')};
  }
`;

const CustomNativeCarousel = styled(Carousel)`
  /* Carousel (slick) */
  .slick-slide {
    height: 0px !important;
  }
  .slick-track {
    height: 100% !important;
  }
  .slick-current {
    height: 100% !important;
  }
  .slick-active {
    height: 100% !important;
  }
  .slick-list {
    height: 100% !important;
  }
`;
const QuesDiv = styled.div`

  margin: auto;
  // margin-top: -30px;
  min-height: 60vh;
  @media only screen and (max-width: 480px) {
    max-width: 60vw;
  }
  padding: ${props => !props.currentIsMatrix && '24px'};
`;


